<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px">
    <v-row no-gutters class="mx-2">
      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          sort-by="createdAt"
          :sort-desc="false"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link
              v-if="item.kind === 'PRODUCT'"
              :to="{ name: 'Study', params: { id: item.id } }"
            >
              <span v-if="item.product">{{ item.product.name }}</span>
              <span v-else>{{ $t("common.misc.NA") }}</span>
            </router-link>
            <router-link
              v-if="item.kind === 'COMPONENT'"
              :to="{ name: 'Study', params: { id: item.id } }"
            >
              {{ item.component.name }}
            </router-link>
          </template>

          <!-- Kind -->
          <template v-slot:item.kind="{ item }">
            <v-chip>{{ item.kind }}</v-chip>
          </template>

          <!-- Project -->
          <template v-slot:item.projectImpact="{ item }">
            <ProjectImpactLink :project-impact="item.projectImpact" />
          </template>

          <!-- Brand Collection -->
          <template v-slot:item.brandCollection="{ item }">
            <BrandCollectionLink :brand-collection="item.brandCollection" />
          </template>

          <!-- State -->
          <template v-slot:item.state="{ item }">
            <v-chip label>{{ item.state }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";

export default {
  name: "CommonStudiesTab",
  components: {
    BrandCollectionLink: () =>
      import("@/components/Common/Cards/BrandCollectionLink"),
    ProjectImpactLink: () =>
      import("@/components/Common/Cards/ProjectImpactLink"),
  },
  mixins: [vuetifyDataTable],

  props: {
    account: { type: Object, default: () => null },
    supplier: { type: Object, default: () => null },
    projectImpact: { type: Object, default: () => null },
    component: { type: Object, default: () => null },
    brandCollection: { type: Object, default: () => null },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("studies.datatable.headers.name"),
          value: "name",
          sortable: false,
          width: "40%",
        },
        {
          text: this.$t("studies.datatable.headers.kind"),
          value: "kind",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("studies.tab.datatable.headers.projectImpact"),
          value: "projectImpact",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("studies.tab.datatable.headers.brandCollection"),
          value: "brandCollection",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("studies.tab.datatable.headers.state"),
          value: "state",
          sortable: false,
          width: "10%",
        },
      ],
      URL_API: {
        GET_ALL: "/studies",
      },

      filters: {
        accounts: this.account ? [this.account.id] : [],
        suppliers: this.supplier ? [this.supplier.id] : [],
        "projects-impact": this.projectImpact ? [this.projectImpact.id] : [],
        components: this.component ? [this.component.id] : [],
        "brand-collections": this.brandCollection
          ? [this.brandCollection.id]
          : [],
      },

      currentStudy: null,
    };
  },
};
</script>

<style scoped></style>
